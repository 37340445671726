<template>
    <div>
        <ValidationObserver ref="formApprovalStep">
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="approver_type" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('approver')">
                            <multiselect :class="errors[0] ? 'box-border-color' : ''" :multiple="false"
                                :options="approver_types" label="text" track-by="value" :placeholder="$t('field')"
                                v-model="innerValue.approver_type" :searchable="true" @input="handleInput($event, false)"
                                :disabled="formDisabled">
                            </multiselect>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="order_type" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('order')">
                            <multiselect :class="errors[0] ? 'box-border-color' : ''" :multiple="false"
                                :options="order_types" label="text" track-by="value" v-model="innerValue.order_type"
                                :searchable="true" @input="handleInput($event, false)" :disabled="formDisabled">
                            </multiselect>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="filter" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('filter_type')">
                            <b-form-select v-model="innerValue.filter" :options="filterOptions">
                            </b-form-select>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <!-- <b-col cols="12" md="6">
                    <b-form-group :label="$t('status')">
                        <ValidationProvider name="statuses" rules="required" v-slot="{ valid, errors }">
                            <b-form-input v-model="innerValue.statuses" :state="errors[0] ? false : null"
                                :disabled="formDisabled" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                        </ValidationProvider>
                    </b-form-group>
                </b-col> -->

                <b-col cols="12" md="6" v-if="approverTypeVal == 'User'">
                    <ValidationProvider name="approver_ids" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('user')">
                            <staff-auto-complete v-model="innerValue.approver_ids" :multiple="true"
                                :validateError="errors[0]" :disabled="formDisabled" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col cols="12" md="6" v-if="approverTypeVal == 'Role'">
                    <ValidationProvider name="roles" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('role')">
                            <role-selectbox :valueType="'id'" :multiple="true" v-model="innerValue.approver_ids"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <template v-for="(status, index) in innerValue.statuses">
                    <b-col cols="12" md="6" :key="index">
                        <ApprovalStatus :value="status" :index="index" :formType="formType"
                            @delete="(i) => innerValue.statuses.splice(i, 1)"
                            @updateValue="(val) => innerValue.statuses[index] = val" @save="saveStatus" />
                    </b-col>
                </template>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
import handleInput from "@/helpers/handleInput";

export default {
    name: "ApprovalStep",
    components: {
        ApprovalStatus: () => import("./ApprovalStatus")
    },
    props: {
        formType: {
            type: String,
            default: ""
        },
        formId: {
            type: Number,
            default: null
        },
        value: {
            type: Object,
            default: () => { },
        },
        index: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            formData: {},
            roleOptions: [],
            formLoading: false,
            systemModelList: [],
            systemFields: [],
            tabIndex: 0,
            approver_types: [
                { text: this.$t('role'), value: "Role" },
                { text: this.$t('user'), value: "User" },
            ],
            order_types: [
                { text: this.$t('ordered'), value: "Ordered" },
                { text: this.$t('unordered'), value: "Unordered" },
            ],
            statutes: [],
            filterOptions: [
            {
                    text: this.$t('select'), value: null,
                },
                {
                    text: this.$t('faculty'), value: "Faculty",
                },
                {
                    text: this.$t('department'), value: "Department",
                },
                {
                    text: this.$t('program'), value: "Program",
                }
            ]
        }
    },
    watch: {},
    computed: {
        approverTypeVal() {
            return this.innerValue.approver_type?.value
        },
        innerValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("updateValue", val);
            },
        },
        innerStatuses: {
            get() {
                return this.value.statuses;
            },
            set(val) {
                this.$emit("updateValue", val);
            },
        },
        formDisabled() {
            return ["delete", "show"].includes(this.formType)
        },
        okButtonText() {
            switch (this.formType) {
                case "create":
                    return this.$t('create')
                case "show":
                    return this.$t('close')
                case "update":
                    return this.$t('update')
                case "delete":
                    return this.$t('delete')
                default:
                    return this.$t('close')
            }
        }
    },
    methods: {
        handleInput,
        saveStatus() {

        }
    },
    created() {
    }
}
</script>
